import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-talent-acquisition',
  templateUrl: './talent-acquisition.component.html',
  styleUrls: ['./talent-acquisition.component.css']
})
export class TalentAcquistionComponent implements OnInit {

  pageTitle = "Talent Acquistion"

  constructor() { }

  ngOnInit(): void {
  }

}
