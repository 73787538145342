import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HomeJoinComponent } from './home-join/home-join.component';
import { HomeSliderComponent } from './home-slider/home-slider.component';
import { HomeComponent } from './home/home.component';
import { CoreValuesComponent } from './core-values/core-values.component';
import { ValuePropositionComponent } from './value-proposition/value-proposition.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { QualityComponent } from './quality/quality.component';
import { ServiceOfferingComponent } from './service-offering/service-offering.component';
import { AllianceComponent } from './alliance/alliance.component';

@NgModule({
  declarations: [
    HomeComponent,
    HomeSliderComponent,
    HomeJoinComponent,
    CoreValuesComponent,
    ValuePropositionComponent,
    AboutusComponent,
    QualityComponent,
    ServiceOfferingComponent,
    AllianceComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
  ]
})
export class HomeModule { }
