import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HomeJoinComponent } from '../home-join/home-join.component';

@Component({
  selector: 'app-home-slider',
  templateUrl: './home-slider.component.html',
  styleUrls: ['./home-slider.component.css']
})
export class HomeSliderComponent implements OnInit {

  constructor(
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
  }

  showAlert(message, showHelp) {
    const modalRef = this.modalService.open(HomeJoinComponent, { centered: true });

    modalRef.componentInstance.title = 'JOIN AS '+message;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.showHelp = showHelp;
    modalRef.componentInstance.buttonCaption = 'Sign Up';
    modalRef.componentInstance.hideCancel = true;
    modalRef.result.then((result) => {
      // do nothing
    }, (reason) => {

    });
  }

}
