import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sap-9-key-offerings',
  templateUrl: './sap-9-key-offerings.component.html',
  styleUrls: ['./sap-9-key-offerings.component.css']
})
export class Sap9KeyOfferingsComponent implements OnInit {

  pageTitle = "SAP-9 Key Offerings"

  constructor() { }

  ngOnInit(): void {
  }

}
