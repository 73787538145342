import { Component, OnInit } from "@angular/core";
// import '../../assets/js/smtp.js';

@Component({
  selector: "app-contactus",
  templateUrl: "./contactus.component.html",
  styleUrls: ["./contactus.component.scss"],
})
export class ContactusComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  sendEmail() {
    Email.send({
      Host: "smtp.elasticemail.com",
      SecureToken: "52393A554F10F46F7962F9023F1FCF6B4231",
      Username: "nexus.innovate.sg@gmail.com",
      Password: "Tsb167nexus#$",
      To: "sthummala@gmail.com",
      From: `sthummala@gmail.com`,
      Subject: "this.model.subject",
      Body: "<i>This is sent as a feedback from Contact Us page.</i> <br/> <b>Name: </b> this.model.name <br /> <b>Email: </b> this.model.email <br /> <b>Subject: </b> this.model.subject <br /> <b>Message:</b> <br /> this.model.message <br><br> <b>~End of Message.~</b> ",
    }).then((message) => {
      alert(
        "Thank you.. Our Business Development person will be in touch with you."
      );
    });
  }
}

const Email: any = {
  send: function (a) {
    return new Promise(function (n, e) {
      (a.nocache = Math.floor(1e6 * Math.random() + 1)), (a.Action = "Send");
      var t = JSON.stringify(a);
      Email.ajaxPost("https://smtpjs.com/v3/smtpjs.aspx?", t, function (e) {
        n(e);
      });
    });
  },
  ajaxPost: function (e, n, t) {
    var a = Email.createCORSRequest("POST", e);
    a.setRequestHeader("Content-type", "application/x-www-form-urlencoded"),
      (a.onload = function () {
        var e = a.responseText;
        null != t && t(e);
      }),
      a.send(n);
  },
  ajax: function (e, n) {
    var t = Email.createCORSRequest("GET", e);
    (t.onload = function () {
      var e = t.responseText;
      null != n && n(e);
    }),
      t.send();
  },
  createCORSRequest: function (e, n) {
    var t = new XMLHttpRequest();
    return (
      "withCredentials" in t
        ? t.open(e, n, !0)
        : // : "undefined" != typeof XDomainRequest
          // ? (t = new XDomainRequest()).open(e, n)
          (t = null),
      t
    );
  },
};
