import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-core-values',
  templateUrl: './core-values.component.html',
  styleUrls: ['./core-values.component.css']
})
export class CoreValuesComponent implements OnInit {

  pageTitle = "Insights"

  constructor() { }

  ngOnInit(): void {
  }

}
