import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ContactusComponent } from "./contactus/contactus.component";
import { NavbarComponent } from "./shared/navbar/navbar.component";
import { FooterComponent } from "./shared/footer/footer.component";
import {
  LocationStrategy,
  HashLocationStrategy,
  PathLocationStrategy,
} from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HomeModule } from "./home/home.module";
import { StaffAugmentationModule } from "./staff-augmentation/staff-augmentation.module";
import { EnterpriseAppsModule } from "./enterprise-apps/enterprise-apps.module";
import { EmployeeModule } from "./employee/employee.module";
import { CareersModule } from "./careers/careers.module";

@NgModule({
  declarations: [
    AppComponent,
    ContactusComponent,
    NavbarComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HomeModule,
    StaffAugmentationModule,
    EnterpriseAppsModule,
    EmployeeModule,
    CareersModule,
  ],
  providers: [{ provide: LocationStrategy, useClass: PathLocationStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
