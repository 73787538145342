import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-staffing-process',
  templateUrl: './staffing-process.component.html',
  styleUrls: ['./staffing-process.component.css']
})
export class StaffingProcessComponent implements OnInit {

  pageTitle = "Staffing Process"

  constructor() { }

  ngOnInit(): void {
  }

}
