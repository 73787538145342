import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { EngagementModelComponent } from './engagement-model/engagement-model.component';
import { JavaComponent } from './java/java.component';
import { MobilityComponent } from './mobility/mobility.component';
import { OverviewComponent } from './overview/overview.component';
import { S4hanaComponent } from './s4hana/s4hana.component';
import { Sap9KeyOfferingsComponent } from './sap-9-key-offerings/sap-9-key-offerings.component';
import { SapResourceMixComponent } from './sap-resource-mix/sap-resource-mix.component';
import { SapSaqApproachComponent } from './sap-saq-approach/sap-saq-approach.component';
import { SharepointMigrationComponent } from './sharepoint-migration/sharepoint-migration.component';
import { SuccessStoriesComponent } from './success-stories/success-stories.component';


@NgModule({
  declarations: [
    EngagementModelComponent,
    JavaComponent,
    MobilityComponent,
    OverviewComponent,
    S4hanaComponent,
    Sap9KeyOfferingsComponent,
    SapSaqApproachComponent,
    SharepointMigrationComponent,
    SuccessStoriesComponent,
    SapResourceMixComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
  ]
})
export class EnterpriseAppsModule { }
