import { Component, OnInit, Input, Pipe, PipeTransform } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormControl } from '@angular/forms';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@Component({
  selector: 'app-home-join',
  templateUrl: './home-join.component.html',
  styleUrls: ['./home-join.component.scss']
})
export class HomeJoinComponent implements OnInit {

  @Input() title = 'Information';
  @Input() message = 'Your message here';
  @Input() buttonCaption = 'OK';
  @Input() buttonCaption1 = 'Cancel';
  @Input() showHelp: boolean = false;
  titleCss: string;
  buttonCss: string;
  formCompleted: boolean = false;
  dialogFormGroup: FormGroup;
  showError = '';

  constructor(
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    if(this.message=='CLIENT') { 
      this.titleCss = 'color-red';
      this.buttonCss = "red-btn-bg";
    }
    else if(this.message=='VENDOR') { 
      this.titleCss = 'color-blue';
      this.buttonCss = "blue-btn-bg";
    }
    else if(this.message=='TALENT') {
      this.titleCss = 'color-green';
      this.buttonCss = "green-btn-bg";
    }
    this.dialogFormGroup = new FormGroup({
      name: new FormControl(),
      phone: new FormControl(),
      email: new FormControl(),
      country: new FormControl(),
      password: new FormControl(),
      confirmPassword: new FormControl()
    });
  }

  showHelpContent() {
    this.showHelp = true;
  }

  submit() {
    if(this.isEmpty(this.dialogFormGroup.value.password) 
    || this.isEmpty(this.dialogFormGroup.value.confirmPassword) 
    || this.isEmpty(this.dialogFormGroup.value.name)
    || this.isEmpty(this.dialogFormGroup.value.phone)
    || this.isEmpty(this.dialogFormGroup.value.email)
    || this.isEmpty(this.dialogFormGroup.value.country)) {
      this.showError = 'All fields are mandatory.';
      return;
    }
    if(this.dialogFormGroup.value.password!=this.dialogFormGroup.value.confirmPassword) {
      this.showError = 'Your password and confirm password should match.';
      return;
    }
    this.formCompleted = true;
  }

  goBack() {
    this.showHelp = false;
  }

  closeModal() {
    if(this.dialogFormGroup && this.dialogFormGroup.value.inputValue) {
      this.activeModal.close(this.dialogFormGroup.value.inputValue);
    } else {
      this.activeModal.close('Close click');
    }
  }
  isEmpty(value) {
    if(value==undefined || value=='') return true;
    return false;
  }
}
