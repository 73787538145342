import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  useFulLinks1 = [
    { path: "/staffing-process", label: "Staffing Process" },
    { path: "/methodology", label: "Methodology" },
    { path: "/workforce", label: "Workforce" },
    { path: "/talent-acquisition", label: "Talent Acquisition" },
    { path: "/job-opportunities", label: "Job Opportunities" },
    { path: "/contactus", label: "Contact Us" },
  ];
  enterpriseApps = [
    { path: "/s4hana", label: "S4HANA & Fiori" },
    { path: "/sap9", label: "SAP-9 Key Offerings" },
    { path: "/engagement-model", label: "Engagement Model" },
    { path: "/success-stories", label: "Success Stories" },
    { path: "/sap-resource-mix", label: "SAP Resource Mix" },
    { path: "/sharepoint-migration", label: "Sharepoint Migration" },
    { path: "/mobility", label: "Mobility" },
    { path: "/java", label: "Java" },
  ];

  constructor(private _router: Router) {}

  ngOnInit(): void {}

  menuItemClicked(menuItem) {
    this._router.navigate([menuItem.path]);
    window.scrollTo(0, 0);
  }
}
