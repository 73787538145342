import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobility',
  templateUrl: './mobility.component.html',
  styleUrls: ['./mobility.component.css']
})
export class MobilityComponent implements OnInit {

  pageTitle = "Mobility"

  constructor() { }

  ngOnInit(): void {
  }

}
