import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alliance',
  templateUrl: './alliance.component.html',
  styleUrls: ['./alliance.component.css']
})
export class AllianceComponent implements OnInit {

  pageTitle = "Alliance"

  constructor() { }

  ngOnInit(): void {
  }

}
