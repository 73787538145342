import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-value-proposition',
  templateUrl: './value-proposition.component.html',
  styleUrls: ['./value-proposition.component.css']
})
export class ValuePropositionComponent implements OnInit {

  pageTitle = "Value Proposition"

  constructor() { }

  ngOnInit(): void {
  }

}
