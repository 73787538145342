import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-s4hana',
  templateUrl: './s4hana.component.html',
  styleUrls: ['./s4hana.component.css']
})
export class S4hanaComponent implements OnInit {

  pageTitle = "S/4HANA & Fiori"

  constructor() { }

  ngOnInit(): void {
  }

}
