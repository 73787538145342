import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hr-function',
  templateUrl: './hr-function.component.html',
  styleUrls: ['./hr-function.component.css']
})
export class HrFunctionComponent implements OnInit {

  pageTitle = "HR Function"

  constructor() { }

  ngOnInit(): void {
  }

}
