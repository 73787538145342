import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sap-saq-approach',
  templateUrl: './sap-saq-approach.component.html',
  styleUrls: ['./sap-saq-approach.component.css']
})
export class SapSaqApproachComponent implements OnInit {

  pageTitle = "SAP SAQ Approach"

  constructor() { }

  ngOnInit(): void {
  }

}
