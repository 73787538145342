import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sap-resource-mix',
  templateUrl: './sap-resource-mix.component.html',
  styleUrls: ['./sap-resource-mix.component.css']
})
export class SapResourceMixComponent implements OnInit {

  pageTitle = "SAP Resource Mix"

  constructor() { }

  ngOnInit(): void {
  }

}
