import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-engagement-model',
  templateUrl: './engagement-model.component.html',
  styleUrls: ['./engagement-model.component.css']
})
export class EngagementModelComponent implements OnInit {

  pageTitle = "Engagement Model"

  constructor() { }

  ngOnInit(): void {
  }

}
