import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";
import { SingaporeJobsComponent } from "./careers/singapore-jobs/singapore-jobs.component";
import { ContactusComponent } from "./contactus/contactus.component";
import { HrFunctionComponent } from "./employee/hr-function/hr-function.component";
import { EngagementModelComponent } from "./enterprise-apps/engagement-model/engagement-model.component";
import { JavaComponent } from "./enterprise-apps/java/java.component";
import { MobilityComponent } from "./enterprise-apps/mobility/mobility.component";
import { OverviewComponent } from "./enterprise-apps/overview/overview.component";
import { S4hanaComponent } from "./enterprise-apps/s4hana/s4hana.component";
import { Sap9KeyOfferingsComponent } from "./enterprise-apps/sap-9-key-offerings/sap-9-key-offerings.component";
import { SapResourceMixComponent } from "./enterprise-apps/sap-resource-mix/sap-resource-mix.component";
import { SapSaqApproachComponent } from "./enterprise-apps/sap-saq-approach/sap-saq-approach.component";
import { SharepointMigrationComponent } from "./enterprise-apps/sharepoint-migration/sharepoint-migration.component";
import { SuccessStoriesComponent } from "./enterprise-apps/success-stories/success-stories.component";
import { AboutusComponent } from "./home/aboutus/aboutus.component";
import { AllianceComponent } from "./home/alliance/alliance.component";
import { CoreValuesComponent } from "./home/core-values/core-values.component";
import { HomeComponent } from "./home/home/home.component";
import { QualityComponent } from "./home/quality/quality.component";
import { ServiceOfferingComponent } from "./home/service-offering/service-offering.component";
import { ValuePropositionComponent } from "./home/value-proposition/value-proposition.component";
import { MethodologyComponent } from "./staff-augmentation/methodology/methodology.component";
import { StaffAugmentationComponent } from "./staff-augmentation/staff-augmentation/staff-augmentation.component";
import { StaffingProcessComponent } from "./staff-augmentation/staffing-process/staffing-process.component";
import { TalentAcquistionComponent } from "./staff-augmentation/talent-acquisition/talent-acquisition.component";
import { WorkforceComponent } from "./staff-augmentation/workforce/workforce.component";

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "home", component: HomeComponent },
  { path: "aboutus", component: AboutusComponent },
  { path: "core-value", component: CoreValuesComponent },
  { path: "value-proposition", component: ValuePropositionComponent },
  { path: "contactus", component: ContactusComponent },
  { path: "quality", component: QualityComponent },
  { path: "service-offering", component: ServiceOfferingComponent },
  { path: "alliance", component: AllianceComponent },
  { path: "staff-augmentation", component: StaffingProcessComponent },
  { path: "methodology", component: MethodologyComponent },
  { path: "staffing-process", component: StaffingProcessComponent },
  { path: "talent-acquisition", component: TalentAcquistionComponent },
  { path: "workforce", component: WorkforceComponent },

  { path: "overview", component: OverviewComponent },
  { path: "s4hana", component: S4hanaComponent },
  { path: "sap-saq-approach", component: SapSaqApproachComponent },
  { path: "sap9", component: Sap9KeyOfferingsComponent },
  { path: "engagement-model", component: EngagementModelComponent },
  { path: "success-stories", component: SuccessStoriesComponent },
  { path: "sap-resource-mix", component: SapResourceMixComponent },
  { path: "sharepoint-migration", component: SharepointMigrationComponent },
  { path: "mobility", component: MobilityComponent },
  { path: "java", component: JavaComponent },
  { path: "job-opportunities", component: SingaporeJobsComponent },
  // { path: 'india-jobs',             component: StaffingProcessComponent },
  // { path: 'malaysia-jobs',             component: TalentAcquistionComponent },
  { path: "hr-function", component: HrFunctionComponent },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
