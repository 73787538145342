import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";

declare var $: any;

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  menuList = [
    {
      path: "/",
      label: "Home",
      subMenu: [
        { path: "/aboutus", label: "About Us" },
        { path: "/core-value", label: "Insights" },
        { path: "/quality", label: "Delivery Excellence" },
        { path: "/value-proposition", label: "Value Proposition" },
        { path: "/service-offering", label: "Service Offering" },
        { path: "/alliance", label: "Alliances" },
      ],
    },
    {
      path: "/staff-augmentation",
      label: "Staff Augmentation",
      subMenu: [
        { path: "/staffing-process", label: "Staffing Process" },
        { path: "/methodology", label: "Methodology" },
        { path: "/workforce", label: "Workforce" },
        { path: "/talent-acquisition", label: "Talent Acquisition" },
      ],
    },
    {
      path: "/overview",
      label: "Enterprise Apps",
      subMenu: [
        { path: "/overview", label: "Overview" },
        { path: "/s4hana", label: "S4HANA & Fiori" },
        { path: "/sap9", label: "SAP-9 Key Offerings" },
        { path: "/engagement-model", label: "Engagement Model" },
        { path: "/success-stories", label: "Success Stories" },
        { path: "/sap-resource-mix", label: "SAP Resource Mix" },
        { path: "/sharepoint-migration", label: "Sharepoint Migration" },
        { path: "/mobility", label: "Mobility" },
        { path: "/java", label: "Java" },
      ],
    },
    {
      path: "/careers",
      label: "Careers",
      subMenu: [
        { path: "/job-opportunities", label: "Job Opportunities" },
        // { path: '/india-jobs', label: 'India Jobs' },
        // { path: '/malaysia-jobs', label: 'Malaysia Jobs' }
      ],
    },
    {
      path: "/employee",
      label: "Employee",
      subMenu: [{ path: "/hr-function", label: "HR Function" }],
    },
    {
      path: "/contactus",
      label: "Contact Us",
      subMenu: [{ path: "/contactus", label: "Contact Us" }],
    },
  ];
  mobileMenuOpen: boolean = false;

  constructor(private _router: Router) {}

  ngOnInit(): void {}

  mobileMenuClicked() {
    this.mobileMenuOpen = !this.mobileMenuOpen;
  }

  menuItemClicked(menuItem) {
    this._router.navigate([menuItem.path]);
    window.scrollTo(0, 0);
    $(".toggle-mobile-menu").click();
  }
}
