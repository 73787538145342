import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-workforce",
  templateUrl: "./workforce.component.html",
  styleUrls: ["./workforce.component.css"],
})
export class WorkforceComponent implements OnInit {
  pageTitle = "Workforce";

  constructor() {}

  ngOnInit(): void {}
}
